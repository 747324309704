<template>
    <div>
        <p style="line-height:36px;margin:0;font-size: 14px;text-indent: 16px;color: #ccc;">邀请点评</p>
        <van-notice-bar mode="closeable" wrapable :scrollable="false" v-if="errMsg" :text="errMsg"/>
        <van-cell-group>
<!--            <van-field label="权益名称" value="达人探店" readonly />-->
            <van-field v-model="form.shop_type_name" v-if="form.shop_type_name" label="店铺类型" readonly />
            <van-field v-model="form.shop_name" label="申请店铺" placeholder="选择店铺" is-link readonly @click="shopSelect = true"  />
            <van-field v-model="form.channel_name" label="等级要求" placeholder="选择等级" is-link readonly @click="channelSelect = true"/>
<!--            <van-field label="发单数量" v-if="maxNum">-->
<!--                <template slot="input">-->
<!--                    <van-stepper v-model="form.num" :max="maxNum" integer />-->
<!--                </template>-->
<!--            </van-field>-->
            <van-field label="区间日期">
                <template slot="input">
                    <van-switch v-model="moreDate" size="24" active-color="#ff6034" @change="form.date = []" />
                </template>
            </van-field>
            <van-field label="探店日期" placeholder="选择探店日期" v-if="!moreDate" :value="form.date.length?(form.date[0].replace(new RegExp('-','g'),'/')):''" is-link readonly @click="dateSelect = true" />
            <van-field label="探店日期" placeholder="选择探店日期区间" v-if="moreDate" :value=" form.date.join('~').replace(new RegExp('-','g'),'/')" is-link readonly @click="dateSelect = true" />
<!--            <van-field label="需要到店">-->
<!--                <template slot="input">-->
<!--                    <van-switch v-model="form.offline" size="24" active-color="#ff6034" />-->
<!--                </template>-->
<!--            </van-field>-->
            <van-field label="店铺图片" v-if="!form.offline">
                <template slot="input" slot-scope="props">
                    <div style="display: flex;flex-wrap: wrap;" @click="chooseImg">
                        <div v-for="item in shop_url" class="van-image van-uploader__preview-image" style="flex: 0 0 33%;padding: 0 5px 5px 0;box-sizing: border-box;">
                            <van-image :src="item" fit="cover" style="height: 80px;width: 80px;display: block;"/>
                            <div class="text" style="color: #646566;font-size: 12px;text-align: center;">图例</div>
                        </div>
                        <div class="van-uploader__upload" v-if="!shop_url.length" style="width: 150px;">
                            <i class="van-icon van-icon-photograph van-uploader__upload-icon"></i>
                            <span style="font-size: 12px;">请一次上传3-9张图片</span>
                        </div>
                    </div>
                </template>
            </van-field>
            <van-field label="发单备注">
                <template #input>
                    <textarea class="myCircle_input" v-model="form.remark" ref="autosize_textarea" @input="autoTextarea" placeholder="请填写您对点评内容的要求或直接编辑好点评内容"></textarea>
                </template>
            </van-field>
        </van-cell-group>
        <div style="width: 100%;padding:16px;box-sizing: border-box;">
            <van-button type="primary" block @click="submit" :loading="submitting" :disabled="errMsg && true"
                        color="linear-gradient(to right,#ff6034,#ee0a24)" style="border-radius: 22px;">确定</van-button>
        </div>
        <van-popup v-model="shopSelect" position="bottom">
            <van-picker show-toolbar :columns="shops" value-key="shop_name"  title="选择探店店铺" @cancel="shopSelect = false" @confirm="shopConfirm" />
        </van-popup>
        <van-popup v-model="channelSelect" position="bottom">
            <van-picker show-toolbar :columns="channels" value-key="channel_name"  title="选择达人等级" @cancel="channelSelect = false" @confirm="channelConfirm" />
        </van-popup>
        <van-calendar v-if="!moreDate" v-model="dateSelect" :show-confirm="false" :min-date="minDate" :max-date="maxDate" @confirm="dateConfirm" />
        <van-calendar v-else v-model="dateSelect" type="range" range-prompt="探店周期不能超过7天" :max-date="maxDate" :show-confirm="false" max-range="7" @confirm="dateConfirms" />
    </div>
</template>

<script>
    import {Toast,Notify,Dialog} from 'vant';
    import {mapState} from 'vuex';
    import wx from "weixin-js-sdk";
    export default {
        name: "ExploreApply",
        computed:{
            ...mapState(['explore','talent']),
        },
        data(){
            return{
                form:{
                    shop_id:'',
                    channel_id:'',
                    num:1,
                    date:[],
                    remark:'',
                    shop_img:[],
                    shop_type_name:'',
                    offline: false,

                    shop_name:'',
                    channel_name:'',
                },
                shop_url:[],
                shops:[],
                channels:[],
                maxNum:0,//可选的最大探店数量
                moreDate: false,
                shopSelect: false,
                channelSelect: false,
                dateSelect: false,
                submitting: false,
                minDate:new Date(new Date().getTime() + 24*60*60*1000),
                maxDate:new Date(new Date().getTime() + 30*24*60*60*1000),
                errMsg:false,
            }
        },
        watch:{
            moreDate(newValue){
                if(newValue === false){
                    Toast('您已切换为单日日期，不能选择今天');
                }
            }
        },
        mounted() {
            this.sdk();
            // 加载店铺
            this.post('/api/client/shop/store').then(result=>{
                if(result.code === 0){
                    this.shops = result.data.info;
                    if(!result.data.verified_shop){
                        this.$dialog.alert({
                            title: '提示',
                            message: '您没有认证通过的店铺，请等待店铺审核通过并设置活动详情后再来发布',
                        }).then(() => {
                            this.$router.back();
                        });
                    }
                } else {
                    Toast(result.msg);
                }
            });
            // 加载探店等级及剩余次数
            this.post('/api/client/shop/explore/channel').then(result=>{
                if(result.code === 0){
                    this.channels = result.data;
                    if(!this.channels.length){
                        Dialog.alert({
                            title: '提示',
                            message: '您没有可申请的探店机会，是否前往购买？',
                            showCancelButton: true,
                        }).then(() => {
                            this.$router.push('/explore/buy');
                        }).catch(() => {
                            this.$router.back();
                        });
                    }
                } else {
                    Toast(result.msg);
                }
            });
            this.$store.dispatch('talent/getExploreChannel');
        },
        methods: {
            sdk(){
                // 接入jssdk
                if(/(Android)/i.test(navigator.userAgent)){
                    this.post('/api/common/jsapi',{url:location.href.split('#')[0]}).then(result=>{
                        if(result.code === 0){
                            wx.config(result.data);
                        }
                    });
                    wx.ready(function () {
                        console.log('wx.ready');
                    });
                    wx.error(function (res) {
                        console.log(res);
                    });
                }
            },
            autoTextarea() {
                this.$nextTick(function () {
                    let textarea = this.$refs.autosize_textarea;
                    textarea.style.height = textarea.scrollHeight + 'px';
                })
            },
            shopConfirm(value) {
                this.form.shop_id = value.shop_id;
                this.form.shop_name = value.shop_name;
                this.form.shop_type_name = value.shop_type_name;
                this.shopSelect = false;
                if(!value.head_img){
                    this.errMsg = '因系统升级，请在“我的店铺”完善此店铺信息后再发单';
                    this.$dialog.alert({ title: '提示', message: this.errMsg, });
                    return;
                }
                if(value.verified !== 1){
                    this.errMsg = '此店铺暂未通过审核，请在“我的店铺”修改店铺信息并查看审核进度';
                    this.$dialog.alert({ title: '提示', message: this.errMsg, });
                    return;
                }
                if(value.order_count >= 3){
                    this.errMsg = '今天发单已达上限，每日限3单，请明天再发';
                    this.$dialog.alert({ title: '提示', message: this.errMsg, });
                    return;
                }
                this.errMsg = false;
            },
            channelConfirm(value){
                this.form.channel_id = value.channel_id;
                this.form.channel_name = value.channel_name;
                this.maxNum = value.remaining_times;
                this.channelSelect = false;
                Notify({ type: 'primary', message: '当前可用的【'+value.channel_name+'】探店余量为【'+value.remaining_times+'】次' });
            },
            formatDate(date){
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                let day = date.getDate();
                return year + '-' + (month < 10 ? "0"+month:month) + '-' + (day < 10 ? "0"+day:day);
            },
            dateConfirm(date) {
                this.form.date = [this.formatDate(date),this.formatDate(date)];
                this.dateSelect = false;
            },
            dateConfirms(date) {
                const [start, end] = date;
                this.form.date = [this.formatDate(start),this.formatDate(end)];
                this.dateSelect = false;
            },
            chooseImg() {
                let that = this;
                wx.chooseImage({
                    count: 9, // 默认9
                    sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
                    sourceType: ['album'], // 可以指定来源是相册还是相机，默认二者都有
                    success: function (result) {
                        that.submitting = true;
                        console.log(result);
                        that.form.shop_img = []; // 清空重新上传
                        that.shop_url = [];
                        that.uploadImage(result);
                    },
                    fail: function (res) {
                        Toast('choose image error');
                        that.post('/api/common/log',{chooseImageError:res}).then(result=>{
                            Toast('choose image error2');
                        });
                    },
                });
            },
            async uploadImage(result) {
                let that = this;
                for(let i=0;i<result.localIds.length;i++){
                    await that.upload(result,i);
                }
                that.submitting = false;
            },
            upload(result,i){
                let that = this;
                return new Promise((resolve, reject) => {
                    wx.uploadImage({
                        localId: result.localIds[i], // 需要上传的图片的本地ID，由chooseImage接口获得
                        isShowProgressTips: 1, // 默认为1，显示进度提示
                        success: function (res) {
                            // 保存服务器端ID
                            that.form.shop_img.push(res.serverId);
                            console.log(that.form.shop_img);
                            // 预览图片
                            if(/(Android)/i.test(navigator.userAgent)){
                                that.shop_url.push(result.localIds[i]);
                                resolve();
                            } else {
                                // ios无法预览，需要base64编码
                                wx.getLocalImgData({
                                    localId: result.localIds[i], // 图片的localID
                                    success: function (res) {
                                        console.log(res);
                                        that.shop_url.push(res.localData);
                                        resolve();
                                    },
                                    fail: function (res) {
                                        console.log(res);
                                        reject();
                                    },
                                });
                            }
                        },
                        fail: function (res) {
                            Notify('图片上传失败，请重试');
                            console.log(res);
                            reject();
                        },
                    });
                })
            },
            submit(){
                let that = this;
                let form = this.form;
                // 验证必填信息
                if(!form.shop_id){
                    Notify('请选择店铺');
                    return;
                }
                if(!form.channel_id){
                    Notify('请选择探店达人等级');
                    return;
                }
                if(!form.date.length){
                    Notify('请选择探店日期');
                    return;
                }
                if(!form.offline){
                    // 如果不需要到店，至少要上传三张照片
                    if(form.shop_img.length < 3){
                        Notify('请上传至少三张图片供达人使用');
                        return;
                    }
                    if(!form.remark){
                        Notify('不要求达人到店时请写明探店需求');
                        return;
                    }
                }
                let message = '您将邀请【'+form.num+'】位达人探访您的【'+form.shop_name+'】店铺';
                if(!form.offline){
                    if(form.num > 1){
                        Notify('不需达人到店时，每次发单仅可邀请一位达人');
                        return;
                    }
                    message = '您将邀请一位达人为您的店铺【'+form.shop_name+'】进行评价';
                }
                Dialog.confirm({
                    title:'请确认',
                    message:message,
                }).then(()=>{
                    this.submitting = true;
                    this.post('/api/client/shop/explore/apply',form).then(result=>{
                        if(result.code === 0){
                            Dialog.alert({
                                title:'申请成功',
                                message:result.msg,
                            }).then(()=>{
                                that.$router.back();
                            });
                        } else {
                            Dialog.alert({
                                title:'申请失败',
                                message:result.msg
                            });
                        }
                    });
                });
            },
        }

    }
</script>

<style scoped>
    .myCircle_input{
        border: none;
        outline: none;
        overflow: hidden;
        resize: none;
        word-break: break-all;
        box-sizing: border-box;
        width: 100%;
        padding: 0;
    }
</style>